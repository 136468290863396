.fyw-font-dark {
    color: $darkBlue !important;
}
.fyw-font-white {
    color: $white !important;
}
.fyw-font-bold {
    font-weight: 600 !important;
}
.fyw-title {
    // font-size: 4em;
    font-weight: 600;
    font-family: $titleFont;
    letter-spacing: 0.1em;
    color: $white;
    margin-bottom: $padding-lg;
}
.fyw-title-dark {
    // font-size: 4em;
    font-weight: 600;
    font-family: $titleFont;
    letter-spacing: 0.1em;
    color: $darkBlue;
    margin-bottom: $padding-lg;
}
.fyw-subtitle {
    // font-size: 2.5em;
    font-weight: 400;
    font-family: $subtitleFont;
    letter-spacing: 0.1em;
}
.fyw-subtitle-white {
    // font-size: 2.5em;
    font-weight: 400;
    font-family: $subtitleFont;
    letter-spacing: 0.1em;
    color: $white;
}
.fyw-subtitle-dark {
    // font-size: 2.5em;
    font-weight: 400;
    font-family: $subtitleFont;
    letter-spacing: 0.1em;
    color: $darkBlue;
}
.fyw-paragraph {
    @include paragraph;
}
.fyw-paragraph-white {
    @include paragraph;
    color: $white;
}
.fyw-paragraph-dark {
    @include paragraph;
    color: $darkBlue;
}
.fyw-paragraph-brown {
    @include paragraph;
    color: $darkPink;
}