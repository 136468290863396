@import './style.scss';

// =============== CONNECTING WITH OTHERS ===============
.carousel-button {
    background-color: $white;
    border-radius: 500px;
    font-size: 30px;
    color: $darkBlue;
    border: 2px solid $darkBlue;
    &:hover {
        background-color: $darkBlue;
        color: $white;
    }
}

// ===================== COPING CARDS =====================
.bg-lightGrey {
    background-color: $lightGrey;
  }
  
  li.drag-box {
    list-style-type: none;
    width: 80%;
    min-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightYellow;
    color: $midPurple;
    margin: 20px 0 20px 0;
    padding: 10px 10px 10px 10px;
    border-radius: 2px;
    transition: .4s ease-in-out;
    &:hover {
      transform: scale(1.1);
      background-color: $lightYellow;
      border-bottom: 2px $midPurple solid;
    }
    background-color: $whiteOpa6;
    border-radius: 20px;
    border: 1px $white solid;
  }
  
  .feedback-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;

    // background-color: $lightGrey;
  }
  .question-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 60%;
    // opacity: 0.9;
    // background-color: $whiteOpa6;
    // border-radius: 20px;
    // border: 1px $white solid;
    position: relative;
  }
  .broken-box-text {
    z-index: 2;
  }
  .broken-box {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    opacity: 0.95;
    z-index: 1;
  }
  .blossom-box {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
    flex-wrap: wrap;
  }
  .row > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
  
  .coping-card-drag-drop {
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
  }

  .fade-out-svg {
    animation: fadeOut $gentle ease-in-out forwards;
  }

  // ===================== SOCIAL COMPASS / IDENTIFY COMPANIONS =====================

  .wheel-panel{
    cursor: pointer;
    transition: $fast;
    fill: $midGrey;
    &.active {
      stroke: $darkPink;
      stroke-width: 6px;
    }
  }
  .cls-2{
    fill:#4d4d4d;
  }
  .cls-3{
    fill:#fff;
  }
  
  #validator-panel {
    &:hover, .active {
      fill: #6E3054;
    }
  }
  #validator-panel-active {
    fill: #6E3054;
  }
  
  #cheerleaders-panel {
    &:hover, .active {
      fill: #9C3A68;
    }
  }
  #cheerleaders-panel-active {
    fill: #9C3A68;
  }

  #problem-solver-panel {
    &:hover, .active {
      fill: #DE3E59;
    }
  }
  #problem-solver-panel-active {
    fill: #DE3E59;
  }

  #companion-panel {
    &:hover, .active {
      fill: #F25A3D;
    }
  }
  #companion-panel-active {
    fill: #F25A3D;
  }

  #listener-panel {
    &:hover, .active {
      fill: #FFA014;
    }
  }
  #listener-panel-active {
    fill: #FFA014;
  }

  #practical-supporter-panel {
    &:hover, .active {
      fill: #FF7040;
    }
  }
  #practical-supporter-panel-active {
    fill: #FF7040;
  }

  .sc-step {
    fill: $darkBlue;
    transition: $fast;
    cursor: pointer;
    &:hover, &.active {
      fill: $lightYellow;
    }
    &.completed {
      fill: $smokeGrey;
    }
  }
  .sc-complete {
    fill: $darkBlue;
  }

// =============================== REHEARSE WHAT TO SAY =============================

  .social-contact-panel {
    border: 1px solid $darkGrey;
    min-height: 60px;
  }

  .sc-contact-accordion-children {
      width: 100%;
      background-color: $lightGrey;
      border-bottom: 2px solid $smokeGrey;
      justify-content: center;
      align-items: flex-start;
      cursor: pointer;
      .sc-contact-accordion-children-selected {
        display: none;
      }
      &:hover {
        background-color: $lightYellow;
        // color: $white !important;
        .sc-contact-accordion-children-selected
        {
          display: inline-block;
          color: $midGreen;
        }
      }
  }

  .sc-reason-block {
    border: 1px solid $midGrey;
    // background-color: $lightGrey;
    // color: $white;
  }
  .pinched {
    position:relative;
    padding:15px;
    margin:50px 0 3em;
    text-align:center;
    color:#fff;
    background: $smokeGrey;
    /* css3 */
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
  }
  
  /* creates a rectangle of the colour wanted for the pointy bit */
  .pinched:before {
    content:"";
    position:absolute;
    top:-20px;
    left:50%;
    width:100px;
    height:20px;
    margin:0 0 0 -50px;
    background: $smokeGrey;
  }
  
  /* creates a rounded rectangle to cover part of the rectangle generated above */
  .pinched:after {
    content:"";
    position:absolute;
    top:-20px;
    left:0;
    width:50%;
    height:20px;
    background:#fff;
    /* css3 */
    -webkit-border-bottom-right-radius:15px;
    -moz-border-radius-bottomright:15px;
    border-bottom-right-radius:15px;
  }
  
  /* creates the other rounded rectangle */
  .pinched > :first-child:before {
    content:"";
    position:absolute;
    top:-20px;
    right:0;
    width:50%;
    height:20px;
    background:#fff;
    /* css3 */
    -webkit-border-bottom-left-radius:15px;
    -moz-border-radius-bottomleft:15px;
    border-bottom-left-radius:15px;
  }

// ==================== MEDIA QUERY =======================
@include media-sm {
    .carousel-button {
        width: 60px;
        height: 60px;
    }
}
@include media-md {
    .carousel-button {
        width: 80px;
        height: 80px;
    }
}
@include media-lg {
    .carousel-button {
        width: 100px;
        height: 100px;
    }
}
@include media-xl {
    .carousel-button {
        width: 120px;
        height: 120px;
    }
}