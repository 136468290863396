@import './style.scss';

[class*="formContainer"],
.formContainer {
    text-align: center;
    margin-top: 20px;
    margin: 5% auto 50px;
}

[class*="formSection"],
.formSection {
    position: relative;
    margin-bottom: 20px;
    background-color: var(--form-color);
    padding: 35px 40px;
    text-align: left;
    display: inline-block;
    min-width: 460px;
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.15);
    box-sizing: border-box;
}

[class*="formField"],
.formField {
    margin-bottom: 22px;
}

[class*="formRow"],
.formRow {
    margin-bottom: 12px;
}

[class*="container"],
.container {
	font-weight: 400;
}

[class*="actionRow"],
.actionRow {
	margin-bottom: 15px;
}

[class*="sectionHeader"],
.sectionHeader {
	color: $darkBlue;
	margin-bottom: 24px;
	font-size: 18px;
	font-weight: 500;
}

[class*="sectionHeaderHint"],
.sectionHeaderHint {
	color: $midGrey;
	font-size: 16px;
	font-weight: 400;
	margin-top: 4px;
}

[class*="sectionBody"],
.sectionBody {
	margin-bottom: 30px;
}


[class*="Section__sectionFooter"],
.sectionFooter {
	font-size: 14px;
	color: $midGrey;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-start;
}

[class*="sectionFooterPrimaryContent"],
.sectionFooterPrimaryContent {
	margin-left: auto;
}

[class*="sectionFooterSecondaryContent"],
.sectionFooterSecondaryContent {
	margin-right: auto;
    align-self: center;
    flex-direction: row;
}


[class*="hint"],
.hint {
    color: $midGrey;
    font-size: 12px;
}

[class*="Button__button"],
.button {
	min-width: 153px;
	display: inline-block;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-image: none;
	color: $darkBlue;
	background-color: $lightYellow;
	border-color: #ccc;
	text-transform: uppercase;
	padding: 14px 0;
	letter-spacing: 1.1px;
	border: none;
}

[class*="button"]:active,
.button:active {
	opacity: 1;
	background-color: var(--button-click);
}

[class*="button"]:hover,
[class*="signInButton"]:hover,
.button:hover,
.signInButton:hover {
	opacity: 0.8;
}

[class*="button"]:disabled,
.button:disabled {
	opacity: 1;
	cursor: auto;
	background-color: var(--ion-color-primary-tint);
}

[class*="signInButton"],
.signInButton {
	position: relative;
	width: 100%;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	padding: 0;
	color: $darkBlue;
	font-size: 14px;
	box-sizing: content-box;
}

#googleSignInButton {
	background-color: #4285f4;
	font-family: Roboto;
	border: 1px solid #4285f4;
	color: var(--white);
}

#googleSignInButton > .signInButtonIcon {
	background-color: var(--white);
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
}

#auth0SignInButton {
	background-color: #eb5424;
	font-family: Roboto;
	border: 1px solid #e14615;
	color: #fff;
}

#auth0SignInButton > .signInButtonIcon {
	border-radius: 4px 0 0 4px;
	height: 28px;
	width: 28px;
	padding: 12px;
	fill: #fff;
}

#facebookSignInButton {
	background-color: #4267b2;
	border-color: #4267b2;
	font-family: 'Helvetica Neue';
	color: var(--white);
}

#facebookSignInButton > .signInButtonIcon {
	height: 33px;
	width: 18px;
	padding: 10px 14px;
}

#amazonSignInButton {
	background-color: var(--amazonOrange);
	border: none;
	color: var(--white);
	font-family: 'Amazon Ember';
}

#amazonSignInButton > .signInButtonIcon {
	padding: 10px;
	height: 32px;
	width: 32px;
}

#oAuthSignInButton {
	background-color: var(--white);
	color: $darkBlue;
}

[class*="signInButtonIcon"],
.signInButtonIcon {
	position: absolute;
	left: 0;
	box-sizing: content-box;
}

[class*="signInButtonContent"],
.signInButtonContent {
	text-align: center;
	display: block;
	padding: 18px 0;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	line-height: initial;
}

[class*="input"],
.input {
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 14px;
    color: $darkBlue;
    background-color: $white;
    background-image: none;
    // border: 1px solid $lightGrey;
    // border-radius: 3px;
    box-sizing: border-box;
    margin-bottom: 10px;
}

[class*="input"]:disabled,
.input:disabled {
    color: #545454;
    background-color: $lightGrey;
}

[class*="inputLabel"],
.inputLabel {
    color: $darkBlue;
    font-size: 14px;
    margin-bottom: 8px;
}

[class*="label"],
.label {
    color: $darkBlue;
}

[class*="radio"],
.radio {
    margin-right: 18px;
    vertical-align: bottom;
}

[class*="selectInput"],
.selectInput {
	display: flex;
}

[class*="selectInput"] > [class*="input"],,
.selectInput > input {
	flex: 1;
	border-radius: 0 3px 3px 0 !important;
}

[class*="selectInput"] > [class*="select"],
.selectInput > select {
	padding: 16px 20px 16px 16px;
	font-size: 14px;
	color: $darkBlue;
	background-color: #fff;
	background-image: none;
	border: 1px solid $lightGrey;
	border-right: none;
	border-radius: 3px 0 0 3px;
	box-sizing: border-box;
	margin-bottom: 10px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	flex-basis: 22%;
	width: 1%;

	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%),
		linear-gradient(to right, #ccc, #ccc);
	background-position: calc(100% - 10px) calc(1em + 8px),
		calc(100% - 4px) calc(1em + 8px), calc(100% - 2.5em) 0.5em;
	background-size: 6px 5px, 6px 5px, 0px 1.5em;
	background-repeat: no-repeat;
}

[class*="Anchor__a"],
.a {
    color: $lightYellow !important;
    cursor: pointer;
}

[class*="Anchor__a"]:hover,
.a:hover {
    text-decoration: underline;
}


@media only screen and (max-width: 599px) {
    [class*="formContainer"] {
        margin: 0;
    }

    [class*="formSection"],
    .formSection {
        width: 100%;
        box-sizing: border-box;
        padding: 35px 25px;
        box-shadow: none;
        border-radius: 0;
        min-width: auto;
    }

    [class*="input"],
    .input {
        font-size: 16px;
    }

    [class*="Section__sectionFooter"],
	.sectionFooter {
		flex-wrap: wrap;
	}

    [class*="sectionFooterPrimaryContent"],
	.sectionFooterPrimaryContent {
		width: 100%;
		margin-bottom: 32px;
	}

    [class*="sectionFooterPrimaryContent"] > [class*="button"],
	.sectionFooterPrimaryContent > button {
		width: 100%;
	}

    [class*="sectionFooterSecondaryContent"],
	.sectionFooterSecondaryContent {
		text-align: center;
		flex: 0 0 100%;
	}
}