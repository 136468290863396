@import '../scss/style.scss';

.fyw-card-group {
    justify-content: space-between;
    .card-unit {
        display: flex;
        min-height: 400px;
        max-height: 450px;
        max-width: 340px;
        min-width: 300px;
        border: 1px solid $darkPink;
        .card-header-container {
            background-color: $lightYellow;
            width: 100%;
            max-height: 200px;
            height: 140px;
            text-align: center;
            display: flex;
            color: $darkBlue;
            font-weight: bolder;
        }
        .card-img-container {
            display: flex;
            max-height: 400px;
            height: 350px;
            .card-img{
                width: 50%;
            }
        }
        .card-body-container{
            @include paragraph;
            color: $darkPink;
            .card-body-text {
                font-size: 1.1em;
            }
        }
    }

}

// ================= IMAGE SLIDER ================

/* Position the image container (needed to position the left and right arrows) */
.img-slider-container {
    position: relative;
}

/* Hide the images by default */
.mySlides {
    &.hide {
        display: none;
    }
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
    cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 40%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

/* Container for image text */
.caption-container {
    text-align: center;
    background-color: #222;
    padding: 2px 16px;
    color: white;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

/* Six columns side by side */
.column {
    float: left;
    width: 20%;
}

/* Add a transparency effect for thumnbail images */
.demo {
    opacity: 0.6;
}

.active,
.demo:hover {
    opacity: 1;
}

// ================= UI ELEMENTS ================
.fyw-divider-white
{
    border-bottom: 1px solid $white !important;
}
.fyw-alert {
    background-color: $lightPink;
    color: $darkBlue;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

// ================= BUTTON ===================
@mixin fyw-btn
{
    @include paragraph;
    background-color: $darkBlue;
    color: $lightYellow;
    border-radius: 5px;
    @include flexCenter;
    transition: $gentle;
}
.fyw-button {
    @include fyw-btn;
    &:hover {
        @include animation(tinyLift $gentle forwards ease-in-out);
    }
    &:active {
        @include animation(tinyDrop $fast forwards ease-in-out);
    }
}
.fyw-button-light {
    @extend .fyw-button;
    background-color: $white;
    color: $darkBlue;
}
.fyw-button-icon {
    margin-right: $padding-sm;
}

@mixin fyw-imagebutton
{
    @include paragraph;
    border: 1px solid $darkPink;
    @include flexCenter;
    flex-direction: column;
    padding: 10px 0 10px 0;
    transition: $gentle;
    background-color: $white;
    &:nth-last-child(1) {
        border-left: transparent;
    }
}
.fyw-imagebutton {
    @include fyw-imagebutton;
    &:hover {
        background-color: $darkBlue;
        color: $lightYellow;
        border-left: 1px solid $darkPink;
        @include animation(tinyScaleUp $fast forwards ease-in-out);
    }
    &:active {
        background-color: $darkBlue;
        color: $lightYellow;
        border-left: 1px solid $darkPink;
        @include animation(tinyScaleDown $fast forwards ease-in-out);
    }
}
.fyw-imagebutton-landscape {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $darkBlue;
    color: $white;
    max-height: 60px;
    max-width: 300px;
    word-break: break-word;
    width: 100%;
    .fyw-imagebutton-icon {
        // max-width: 70px;
        // max-height: 70px;
        // width: 30%;
        
        height: 60px !important;
        background-color: $lightYellow;
        &.horizontal {
            // max-width: 60px;
        }
    }
}

.fyw-imagebutton-light {
    @extend .fyw-imagebutton;
    background-color: $white;
    color: $darkPink;
}
.fyw-image-icon {
    max-width: 40px;
    max-height: 50px;
    &.horizontal {
        max-width: 60px;
    }
}

// ================= CENTERED NAVBAR ===================
.fyw-nav-icon-img {
    width: 100%;
}
.fyw-nav-title {
    color: $darkBlue;
    font-weight: 500;
}
.fyw-nav-icon {
    &:hover {
        background-color: $lightYellow;
        .fyw-nav-icon-img {
            @include animation(tinyBob $gentle ease-in-out infinite);
        }
    }
    &.active {
        // background-color: $darkBlue;
        .fyw-nav-title {
            color: $darkBlue;
        }
    }
}
.navbar-toggler {
    // background-color: $darkBlue;
}
.fyw-compass-collapse-logo {
    width: 40px;
}
.navbar-color-top {
    background-color: $lightYellow;
    height: 40px;
    z-index: -1;
    position: absolute;
}
@media screen and (min-width:768px){
    .navbar-brand-centered {
        position: absolute;
        left: 50%;
        display: block;
        width: 160px;
        text-align: center;
        background-color: #eee;
    }

    .navbar>.container .navbar-brand-centered, 
    .navbar>.container-fluid .navbar-brand-centered {
        margin-left: -80px;
    }
}

// =============== FOOTER ====================
.fyw-footer-container {
    background-color: $darkBlue;
}


// =============== MEDIA QUERY ====================
@include media-sm {
    .fyw-button {
        min-width: $btn-width-sm;
        min-height: $btn-height-sm;
    }
    .fyw-imagebutton,
    .fyw-imagebutton-landscape {
        min-width: $btn-width-sm;
    }
    .fyw-card-group {
        justify-content: center;
    }
    .fyw-nav-icon-img{
        width: 40px;
    }
    .fyw-nav-title {
        font-size: $font-md;
    }
    .navbar-color-top {
        height: 60px;
    }
}
@include media-md {
    .fyw-button {
        min-width: $btn-width-md;
        min-height: $btn-height-md;
    }
    .fyw-imagebutton,
    .fyw-imagebutton-landscape {
        min-width: $btn-width-md;
    }
    .fyw-card-group {
        justify-content: center;
    }
    .fyw-nav-icon,
    .fyw-nav-icon.active{
        width: 30% !important;
        .fyw-nav-icon-img {
            width: 60px;
        }
    }
    .fyw-nav-title {
        font-size: $font-md;
    }
    .navbar-color-top {
        height: 60px;
    }
}
@include media-lg {
    .fyw-button {
        min-width: $btn-width-lg;
        min-height: $btn-height-lg;
    }
    .fyw-imagebutton,
    .fyw-imagebutton-landscape {
        min-width: $btn-width-lg;
    }
    .fyw-nav-icon,
    .fyw-nav-icon.active{
        width: 30% !important;
        .fyw-nav-icon-img {
            width: 70px;
        }
    }
    .fyw-nav-title {
        font-size: $font-md;
    }
    .navbar-color-top {
        height: 60px;
    }
}
@include media-xl {
    .fyw-button {
        min-width: $btn-width-lg;
        min-height: $btn-height-lg;
    }
    .fyw-imagebutton,
    .fyw-imagebutton-landscape {
        min-width: $btn-width-lg;
    }
    .fyw-nav-icon,
    .fyw-nav-icon.active{
        width: 40% !important;
        .fyw-nav-icon-img {
            width: 80px;
        }
    }
    .fyw-nav-title {
        font-size: $font-md;
    }
    .fyw-compass-logo {
        width: 160px;
    }
    .fyw-compass-collapse-logo {
        display: none;
    }
}

// ==================== FONT =====================
.fyw-font-tip {
    @include tip;
    font-size: $font-sm;
}

// ==================== Material UI tweak ===================

.fyw-form-group {
    &:nth-child(even) {
        background-color: $lightGrey;
    }
    &.fyw-form-selected {
        border-left: 10px solid $midGreen;
    }
    &.fyw-form-group-incomplete {
        border-left: 10px solid $midPink;
    }
    &:hover {
        border-left: 4px solid $darkBlue;
        background-color: $lightGrey;
    }
}
// .MuiIconButton-root {
//     color: $lightYellow !important;
// }
.MuiRadio-colorPrimary.Mui-checked, .MuiRadio-colorSecondary.Mui-checked {
    color: $darkBlue !important;
}
.radio-set-inline {
    display: flex;
    flex-direction: row !important;
}
@include media-sm {
    .fyw-form-group {
        padding: $padding-sm $padding-sm $padding-sm $padding-sm;
    }
}
@include media-md {
    .fyw-form-group {
        padding: $padding-lg $padding-md $padding-lg $padding-md;
    }
}
@include media-lg {
    .fyw-form-group {
        padding: $padding-lg $padding-md $padding-lg $padding-md;
    }
}
@include media-xl {
    .fyw-form-group {
        padding: $padding-lg $padding-md $padding-lg $padding-md;
    }
}
