@import 'style';

.connecting-icon {
    width: 60px;

}

.module-progress-bar-component {
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}