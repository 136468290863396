@import 'style';

.left-wrapper {
    background-color: $darkBlue;
    height: 100vh;
}

.right-wrapper {
    position: relative;
    overflow: auto;
    height: 100vh;
}

.index-container {
    padding: 0;
}
.index-grid {
    background-color: $darkBlue;
    height: 100vh;
}
.card-container {
    display: flex;
}
.registration-container {
    display: flex;
    height: 100vh;
    background-color: $lightYellow;
    .content-element {
        height: 80%;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
.fyw-title {
    font-size: 3em;
    font-weight: 300;
    font-family: $titleFont;
    letter-spacing: 0.1em;
    color: $white;
    margin-bottom: $padding-lg;
}
.fyw-title-dark {
    font-size: 3em;
    font-weight: 300;
    font-family: $titleFont;
    letter-spacing: 0.1em;
    color: $darkBlue;
    margin-bottom: $padding-lg;
}
.fyw-subtitle-white {
    font-size: 2.5em;
    font-weight: 300;
    font-family: $subtitleFont;
    letter-spacing: 0.1em;
    color: $white;
}
.fyw-subtitle-dark {
    font-size: 2.5em;
    font-weight: 300;
    font-family: $subtitleFont;
    letter-spacing: 0.1em;
    color: $darkBlue;
}
.fyw-paragraph {
    @include paragraph;
}
.fyw-paragraph-white {
    @include paragraph;
    color: $white;
}
.fyw-paragraph-dark {
    @include paragraph;
    color: $darkBlue;
}
.fyw-paragraph-brown {
    @include paragraph;
    color: $darkPink;
}
.fyw-paragraph-grey {
    @include paragraph;
    color: $darkGrey;
}

.fyw-tip {
    font-size: $font-md;
    @include paragraph;
    line-height: 10px;
}
.fyw-tip-dark {
    @include paragraph;
    color: $darkBlue;    
}
.fyw-tip-white {
    @include paragraph;
    color: $white;    
}
.fyw-tip-brown {
    @include paragraph;
    color: $darkPink;    
}
.fyw-font-green {
    color: $midGreen;
}
.fyw-font-red {
    color: $midPink;
}
.fyw-font-grey {
    color: $midGrey;
}
.fyw-btn-light
{
    color: $darkBlue !important;
    background-color: $lightYellow !important;
    border: 0px !important;
}

.bottom-line {
    border-bottom: 2px solid $darkBlue;
}
.bottom-line-white {
    border-bottom: 2px solid $white;
}
.fyw-bg-logo {
    height: 100%;
}
// .fyw-primary-btn {
//     background-color: $darkBlue;
//     @include title;
//     color: $white;
//     &:hover {
//         background-color: $darkBlue;
//         color: $lightYellow;
//         border: 2px solid $darkBlue;
//         animation: tinyBob $gentle ease-in-out forwards;
//     }
// }
// .fyw-secondary-btn {
//     background-color: $lightYellow;
//     // border: 2px solid $darkBlue;
//     color: $darkBlue;
//     @include title;
//     &:hover {
//         background-color: $darkBlue;
//         color: $lightYellow;
//         animation: tinyBob $gentle ease-in-out forwards;
//     }
// }
.fyw-btn-dark {
    background-color: $darkBlue !important;
    color: $white !important;
    &.secondary {
        background-color: $white !important;
        color: $darkBlue !important;
        border: 2px solid $darkBlue;
    }
}
.compass-logo-container {
    height: 120px;
    max-height: 160px;
    max-width: 100%;
}

.fyw-compass-logo {
    height: 120px;
    max-width: 100%;
}

.fyw-btn-light {
    background-color: $lightYellow;
    color: $darkBlue !important;
    border: 2px solid $darkBlue;
    &:hover {
        border: 2px solid $darkBlue;
        animation: tinyBob $gentle ease-in-out forwards;
    }
}

.fyw-primary-btn {
    background-color: $lightYellow !important;
    @include title;
    color: $darkBlue !important;
    &:hover {
        background-color: $darkBlue !important;
        color: $lightYellow !important;
        border: 2px solid $darkBlue !important;
        animation: tinyBob $gentle ease-in-out forwards;
    }
}
.fyw-secondary-btn {
    background-color: $lightGrey !important;
    // border: 1px solid $darkBlue;
    color: $darkBlue !important;
    @include title;
    &:hover {
        background-color: $darkBlue !important;
        color: $lightYellow !important;
        animation: tinyBob $gentle ease-in-out forwards;
    }
}

.fyw-dark-bg {
    background-color: $darkBlue;
}
.fyw-white-bg {
    background-color: $white;
}
.fyw-yellow-bg {
    background-color: $lightYellow;
}

.fyw-scale-card {
    max-width: 100%;
}

.fyw-alert {
    background-color: $lightPink;
    color: $darkBlue;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.wheel-tip {
    position: absolute;
    left: 0;
    right: 0;
}

// =============== GRIEFMAP PAGE ====================
.btn-circular-loader {
    position: absolute;
    color: $white !important;
    margin: auto;
    &.dark {
        color: $darkBlue !important;
    }
}

// =============== ERROR PAGE ====================
.error-container {
    background-color: $white;
}
.fyw-error-msg {
    animation: fadeIn $gentle ease-in-out forwards;
}

// =============== MEDIA QUERY ====================
@include media-sm {
    .fyw-article-image {
        width: 100%;
    }
    .left-wrapper {
        // position: absolute;
        // z-index: 999;
        // min-width: 50px;
        transition: $gentle ease-in-out;
        .fyw-nav-title, 
        .fyw-nav-subtitle, 
        .fyw-list-text
        {
            display: none;
        }
        .fyw-list-icon {
            margin-left: auto;
            margin-right: auto;
        }
        .fyw-list-btn {
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
        &.show {
            min-width: 300px !important;
            .fyw-nav-title, 
            .fyw-nav-subtitle, 
            .fyw-list-text
            {
                display: block;
            }
            .fyw-list-icon {
                margin-right: 1rem;
            }
            .fyw-list-btn {
                height: 50px;
            }
        }
    }
    form {
        width: 100%;
    }
    .fyw-title,
    .fyw-title-dark {
        font-size: 1.6em;
    }
    .fyw-subtitle-white,
    .fyw-subtitle-dark {
        font-size: 1.4em;
    }
    .index-grid {
        padding: 0;
    }
    .fyw-paragraph,
    .fyw-paragraph-white,
    .fyw-paragraph-dark 
    {
        font-size: $font-sm;
    }
    .login-btn-container {
        flex-direction: column;
        justify-content: center;
    }
    .fyw-primary-btn,
    .fyw-secondary-btn {
        width: 100%;
    }
    .primary-btn-container {
        order: 1;
    }
    .secondary-btn-container {
        order: 2;
    }
}
@include media-md {
    .fyw-article-image {
        width: 100%;
    }
    .left-wrapper {
        // position: absolute;
        // z-index: 999;
        // min-width: 50px;
        transition: $gentle ease-in-out;
        .fyw-nav-title, 
        .fyw-nav-subtitle, 
        .fyw-list-text
        {
            display: none;
        }
        .fyw-list-icon {
            margin-left: auto;
            margin-right: auto;
        }
        .fyw-list-btn {
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
        &.show {
            min-width: 300px !important;
        }
    }
    .fyw-title,
    .fyw-title-dark {
        font-size: 2.2em;
    }
    .fyw-subtitle-white,
    .fyw-subtitle-dark {
        font-size: 2em;
    }
    .fyw-paragraph,
    .fyw-paragraph-white,
    .fyw-paragraph-dark 
    {
        font-size: $font-sm;
    }
    .login-btn-container {
        flex-direction: column;
        justify-content: center;
    }
    .fyw-primary-btn,
    .fyw-secondary-btn {
        width: 100%;
    }
    .primary-btn-container {
        order: 1;
    }
    .secondary-btn-container {
        order: 2;
    }
}
@include media-lg {
    .fyw-paragraph,
    .fyw-paragraph-white,
    .fyw-paragraph-dark 
    {
        font-size: $font-md;
    }
    .login-btn-container {
        flex-direction: row;
        justify-content: center;
    }
}
@include media-xl {
    .fyw-paragraph,
    .fyw-paragraph-white,
    .fyw-paragraph-dark 
    {
        font-size: $font-lg;
    }
    .card-container {
        height: 100vh;
    }
}