@import 'style';

.divider-text {
    position: relative;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
.divider-text span {
    padding: 7px;
    font-size: 12px;
    position: relative;   
    z-index: 2;
}
.divider-text:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px solid #ddd;
    top: 55%;
    left: 0;
    z-index: 1;
}

.show-pass {
    position: absolute;
    cursor: pointer;
    right: 0;
    margin-right: 10px;
    padding: 10px;
    z-index: 999;
    :hover {
        color: $midGreen;
    }
    &.active {
        color: $darkBlue;
    }
    &.inactive {
        color: $whiteOpa6;
    }
}
/* Add a green text color and a checkmark when the requirements are right */
.pass-valid-section {
    background-color: $whiteOpa6;
    padding: $padding-sm;
    // border-radius: 10px;
    // position: absolute;
    // right: -100%;
    max-width: 100%;
    width: 100%;
    display: none;
    &.hide {
        animation: fade $gentle;
    }
    &.show {
        animation: fadeIn $slow;
        display: block;
    }
}


.pass-validation {
    color: $darkBlue;
}
.valid {
    // color: $midGreen;
}
.valid:before {
    position: relative;
    left: -2px;
    content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
    color: $midPink;
}

.invalid:before {
    position: relative;
    left: -2px;
    content: "✖";
}

.registration-container {
    display: flex;
    min-height: 100vh;
    background-color: $lightYellow;
    .content-element {
        height: 80%;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}