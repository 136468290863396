//========= ANIMATIONS ====================

//================= TRANSITION SPEED ================
$fast: .2s;
$gentle: .6s;
$slow: .8s;

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@keyframes bob {
    0% {transform: translateY(0px);}
    50% {transform: translateY(-15px);}
    100% {transform: translateY(0px);}
}
@keyframes tinyBob {
    0% {transform: translateY(0px);}
    50% {transform: translateY(-5px);}
    100% {transform: translateY(0px);}
}
@keyframes rotate {
    0% {transform: rotate(0deg);}
    50% {transform: rotate(360deg);}
    100% {transform: rotate(720deg);}
}
@keyframes fade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}
@include keyframes(show) {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        opacity: 1;
        display: flex;
    }
}
$fadeYDistance: -80px;
@include keyframes(fadeIn) {
    0% {
        visibility: visible;
        opacity: 0;
        transform: translateY($fadeYDistance);
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
}

@include keyframes(fadeOut) {
    0% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
    }
    100% {
        opacity: 0;
        transform: translateY($fadeYDistance);
        visibility: hidden;
        display: none;
    }
}
@keyframes lift {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-20px);
    }
}

@keyframes tinyLift {
    0% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-5px);
    }
}

@keyframes drop {
    0% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes tinyDrop {
    0% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes zoomIn {
    0% {
        background-size: 100% auto;
    }
    100% {
        background-size: 110% auto;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1.1);
    }
    50% {
        transform: scale(0.6);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes tinyScaleUp {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}

@keyframes tinyScaleDown {
    0% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes clickDown {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slideUp {
    0% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes flyUp {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes openPanel {
    0% {
        height: 0;
        opacity: 0;
    }
    100% {
        height: 30%;
        opacity: 1;
    }
}

@include keyframes(closePanel)
{
    0% {
        height: 30%;
        opacity: 1;
    }
    100% {
        height: 0;
        opacity: 0;
    }
}

@include keyframes(pop) {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@include keyframes(increaseHeight) {
    0% {
        height: 10%;
    }
    100% {
        height: 75%;
    }
}
@include keyframes(reduceHeight) {
    0% {
        height: 78%;
    }
    100% {
        height: 10%;
    }
}

@include keyframes(leftBounce) {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(0px);
    }
}

@include keyframes(rightBounce) {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(20px);
    }
    100% {
        transform: translateX(0px);
    }
}

