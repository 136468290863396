@import 'style';

$midGreen: #59A099;
$lightGreen: #D7F1EE;

.cursor-pointer {
    cursor: pointer;
}

.right-border {
    border-right: 1px solid $midGreen;
}

.match-interaction-btn {
    min-width: 240px;
    width: 50%;
    &:hover {
        background-color: $lightYellowOpa2;
    }
}

.match-card {
    max-width: 260px;
    border-radius: 10px !important;
    // padding: 0 !important;
}

.match-card-img {
    width: 100%;
    // max-height: 120px;
}

.match-avatar {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    background-color: $midGreen;
    @include flexCenter;
}

.match-avatar-sm {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: $lightYellow;
    color: $darkPink;
    @include flexCenter;
}

.midgreen-avatar {
    @extend .match-avatar;
    background-color: $midGreen;
    color: $white;
    font-weight: bold;
}

.href-link {
    @extend .cursor-pointer;
    &:hover {
        color: $midGreen;
        text-decoration: underline;
    }
}

$matchBorderRadius: 10px;
.match-paper {
    @include box-shadow;
    border-radius: $matchBorderRadius;
}

.match-icon {
    width: 48px;
    height: 48px;
}

.match-icon-sm {
    width: 30px;
    height: 30px;
}
.match-nav-btn {
    border: transparentize($color: #000000, $amount: 0);
    &:hover {
        color: $darkBlue;
        background-color: $lightGreen;
    }
}
.match-nav-btn-active {
    background-color: $lightGreen;
    // color: $lightYellow;
}

.supportive-message-bubble {
    background-color: $lightYellowOpa2;
    color: $darkPink;
    border-radius: 10px;
    border: 1px solid $lightYellowOpa4;
}

.match-avatar-container {
    background-color: $darkBlue;
    border-top-left-radius: $matchBorderRadius;
    border-bottom-left-radius: $matchBorderRadius;
}

.match-chat-avatar-active {
    background-color: $lightGreen;
}

.chat-story-container {
    background-color: $lightGreen;
    color: $darkGrey;
    width: 100%;
}

.matchmaking-chat-container {
    width: 100%;
    border: solid 1px #EEE;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.matchmaking-message-bubble {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}
  
.matchmaking-message-bubble {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
}

.yours {
    align-items: flex-start;
}

.yours.matchmaking-message-bubble {
    margin-top: 15px;
    margin-right: 25%;
    background-color: #eee;
    position: relative;
    // display: inline-block;
}

.yours.matchmaking-message-bubble.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
}
.yours.matchmaking-message-bubble.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
}

.mine {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    
    // text-align: end;
}

.mine.matchmaking-message-bubble  {
    color: white;
    margin-top: 15px;
    margin-left: 45%;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    position: relative;
    // display: inline-block;
}

.mine.matchmaking-message-bubble.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
}

.mine.matchmaking-message-bubble.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
}

@include media-sm {
    .match-nav-btn {
        max-width: 100px !important;
        min-width: 60px !important;
        font-size: 0.8em;
    }
}

@include media-md {
    .match-nav-button {
        max-width: 100px !important;
        min-width: 60px !important;
        font-size: 0.8em;
    }
}

@include media-lg {

}

@include media-xl {
    
}
