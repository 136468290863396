/**
* Here I define the color palettes
*/
$lightGreen: #B6BA9F;
$lightGreenOpa: rgba(182, 186, 159, .8);
$midGreen: #5d7437;
$darkGreen: #262D20;
$darkGreenOpa: rgba(38, 45, 32, .8);
$lightPink: #fdc5c4;
$midPink: #c44e4a;
$darkPink: #644857;
$darkPinkOpa: rgba(100,72,87, 0.8);
$lightYellow: #ffc107;
$lightYellowOpa4: rgba(252, 200, 127, 0.4);
$lightYellowOpa2: rgba(252, 200, 127, 0.2);
$darkBlue: #31605b;
$white: #FFFFFF;
$whiteOpa8: rgba(255,255,255, .8);
$whiteOpa6: rgba(255,255,255, .6);
$whiteOpa4: rgba(255,255,255, .4);
$whiteOpa2: rgba(255,255,255, .2);
$lightGrey: #FAFAFA;
$smokeGrey: #EFEFEF;
$midGrey: #6A6C6E;
$darkGrey: #36454F;

// $orange: #F89F50;
$midPurple: #492A44;
$midred: #E45251;
$pink: #E45251;

/**
 *  Predefined class in color
 */
.bg-white {
    background-color: $white;
}
.bg-dark-pink {
    background-color: $darkPink;
}
.bg-light-pink {
    background-color: $lightPink;
}
.bg-dark-green {
    background-color: $darkGreen;
}
.bg-light-green {
    background-color: $lightGreen;
}

/**
* Here I define the font pairs
*/
@import url('https://fonts.googleapis.com/css?family=Asap:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Mukta:300,400,500,600,700&display=swap');
$titleFont: 'Asap', sans-serif;
$subtitleFont: 'Asap', sans-serif;
$paragraphFont: 'Asap', sans-serif;

.background-pattern {
    // width: 100%;
    // height: 100%;
    // background-image: $background-pattern;
    background-repeat: repeat;
    background-size: 5%;
    background-color: $darkGreen;
    background-blend-mode: soft-light;
}

/*
* * Define the padding
*/
$padding-sm: 10px;
$padding-md: 25px;
$padding-lg: 40px;

/**
* Define the height of button
*/
$btn-height-sm: 30px;
$btn-height-md: 35px;
$btn-height-lg: 40px;

/**
* Define the width of button
*/
$btn-width-sm: 180px;
$btn-width-md: 200px;
$btn-width-lg: 220px;

/**
* Define second type of button
*/
$btn2-width-sm: 60px;
$btn2-width-md: 80px;
$btn2-width-lg: 100px;
$btn2-width-xl: 120px;


/**
* Define sizes of btn
*/
@mixin btn-lg {
    height: $btn-height-lg;
    width: $btn-width-lg;
}
@mixin btn-md {
    height: $btn-height-md;
    width: $btn-width-md;
}
@mixin btn-sm {
    height: $btn-height-sm;
    width: $btn-width-sm;
}


/**
*   Style the basic dom selector
*/
*, html, body {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
input:focus, textarea {
    outline: none !important;
}
button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
}

@mixin absCenterEl {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@mixin centerEl {
    margin-left: auto;
    margin-right: auto;
}

@mixin flexCenter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@mixin hideScrollBar {
    // hide the scrollbar
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    /* width */
    &::-webkit-scrollbar {
        width: 0px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent; 
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: transparent; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: transparent; 
    }
}

/** 
* Define the font sizes
*/
$font-xl: 24px;
$font-lg: 20px;
$font-md: 16px;
$font-sm: 14px;
$font-xs: 12px;


@mixin title {
    font-family: $titleFont;
    font-weight: 400;
    @include centerEl;
}

@mixin subtitle {
    font-family: $subtitleFont;
    font-weight: 300;
}

@mixin paragraph {
    font-family: $paragraphFont;
    font-weight: 300;
}

@mixin tip {
    font-family: $paragraphFont;
    font-weight: 300;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-column-even {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
}
@mixin media-xs {
    @media only screen and (max-width: 575.98px) {
        @content;
    }
}

@mixin media-sm {
    @media only screen and (max-width: 767.98px) {
        @content;
    }
}

@mixin media-md {
    @media only screen and (min-width: 768px) and (max-width: 991.98px) {
        @content;
    }
}

@mixin media-lg {
    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        @content;
    }
}

@mixin media-xl {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin box-shadow {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

@mixin text-shadow {
    text-shadow: 0px 0px 10px rgba(0,0,0,0.75);
}

@mixin transitions($params) {
    -webkit-transition: $params;
    -moz-transition: $params;
    -o-transition: $params;
    transition: $params;
}

@mixin animation($animations) {
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

//========== OTHER USEFUL CLASSES =========

.vertical-align {
    display: inline-block;
    vertical-align: middle;
}
.white-subtitle {
    @include subtitle;
    color: $white;
}

.row.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}
.row.row-eq-height > [class*='col-'] {
    display: flex;
    flex-direction: column;
}

.divider {
    border-bottom: 1px solid $darkBlue;
}

.hide {
    display: 'none';
}

.pointer {
    cursor: pointer;
}